import * as cn from "classnames";
import { SearchIcon } from "lucide-react";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import {
  NumberParam, StringParam, useQueryParams, withDefault
} from "use-query-params";

import Pagination from "~/src/features/pagination/index.jsx";

import usePersonNames from "~/src/hooks/use-person-names.js";

import MainContainer from "~/src/ui/containers/main-container/index.jsx";
import PageHeader from "~/src/ui/headers/page-header/index.jsx";
import Page from "~/src/ui/page/index.jsx";

import { genderLabels, sortFilterLabels } from "../modules/labels.jsx";
import Button from "../ui/buttons/button/index.jsx";
import Field from "../ui/forms/field/index.jsx";

import { PersonNamesList } from "./person-names/_exports.js";

const defaultName = "";
const defaultSortBy = "name-asc";
const defaultGender = "null";

const selectConfigs = /** @type {const} */ ([
  {
    label: "Geschlecht",
    name: "gender",
    options: [
      ...genderLabels,
      ["null", "nicht zugeordnet"],
      ["all", "alle"]
    ]
      .toSorted(([keyA], [keyB]) => {
        const sorting = [
          "null",
          "male",
          "female",
          "unknown",
          "all"
        ];

        return sorting.indexOf(keyA) - sorting.indexOf(keyB);
      })
      .map(([value, label]) => ({
        label,
        value
      }))
  },
  {
    label: "Sortieren nach",
    name: "sortBy",
    options: sortFilterLabels.personNames
  }
]);

/**
 *
 * @example
 */
const PersonNamesPage = () => {
  const form = useForm({
    defaultValues: {
      gender: defaultGender,
      name: defaultName,
      sortBy: defaultSortBy
    }
  });

  const {
    control,
    formState: { errors },
    register,
    setValue,
    watch
  } = form;

  const [lastMutate, setLastMutate] = useState(Temporal.Now.instant());

  const [showProjectIds, setShowProjectIds] = useState(false);

  const [query, setQuery] = useQueryParams({
    gender: withDefault(StringParam, defaultGender),
    name: withDefault(StringParam, defaultName),
    page: withDefault(NumberParam, 1),
    sortBy: withDefault(StringParam, defaultSortBy)
  });

  const {
    page
  } = query;

  const handlePageChange = (value) => {
    setQuery({ page: value });
  };

  const handleNameChange = ({ target: { value } }) => {
    setQuery({ name: value });
  };

  const handleSortByChange = (value) => {
    setQuery({ sortBy: value });
  };

  const handleGenderChange = (value) => {
    setQuery({ gender: value });
  };

  const {
    isLoading,
    mutate,
    pagination,
    personNames
  } = usePersonNames(query);

  const sortBy = watch("sortBy");
  const gender = watch("gender");

  useEffect(() => {
    handleSortByChange(sortBy);
  }, [sortBy]);

  useEffect(() => {
    handleGenderChange(gender);
  }, [gender]);

  return (
    <Page className="flex w-full flex-col gap-8" title="Personeninformationen">

      <PageHeader className="w-full">
        <h2 className="text-2xl font-bold leading-7 text-gray-800 sm:truncate sm:text-3xl">
          Personeninformationen
        </h2>
      </PageHeader>

      <MainContainer className="flex w-full flex-col gap-8">
        <div className="flex items-end gap-4">
          <form className="flex items-end gap-4">
            <Field
              hasLabel={false}
              icon={SearchIcon}
              label="Name"
              name="name"
              onChange={handleNameChange}
              {...{
                errors,
                register
              }}
            />

            {
              selectConfigs
                .map(({
                  label,
                  name,
                  options
                }) => (
                  <div key={name}>
                    <label className="block text-sm font-medium text-gray-700">{label}</label>

                    <select
                      className="mt-1 block w-full rounded-md border-gray-300 py-1 pl-3 pr-10 text-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-xs"
                      value={query[name]}
                      {...register(name)}
                    >
                      {
                        options.map(({ label: optionLabel, value: optionValue }) => (
                          <option key={optionValue} value={optionValue}>{optionLabel}</option>
                        ))
                      }
                    </select>
                  </div>
                ))
            }

          </form>

          <Button
            label="Projekt-IDs"
            className={cn({
              "bg-gray-700 text-white! border border-gray-700": showProjectIds,
              "bg-white text-gray-700! border border-gray-700 hover:bg-gray-50!": !showProjectIds
            })}
            onClick={() => {
              setShowProjectIds(!showProjectIds);
            }}
          />
        </div>

        <Pagination labels={["Namen", "Namen"]} page={page} pagination={pagination} setPage={handlePageChange} />

        <PersonNamesList
          isLoading={isLoading}
          lastMutate={lastMutate}
          mutate={mutate}
          personNames={personNames}
          setLastMutate={setLastMutate}
          showProjectIds={showProjectIds}
        />

        <Pagination labels={["Namen", "Namen"]} page={page} pagination={pagination} setPage={handlePageChange} />
      </MainContainer>

    </Page>
  );
};

export default PersonNamesPage;
